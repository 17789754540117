.help-link {
  background-color: $body-bg;
  max-width: 500px;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 20px 10px;
  border-radius: 5px;
  & :first-child {
    flex: 1;
  }
  & :nth-child(2) {
    color: $blue;
    size: 50;
  }

  &:hover {
    background-color: lighten($body-bg, 10%);
  }
}
