.table-space {
  border-collapse: collapse;

  tbody tr {
    border-left: 4px solid transparent;
    &:not(.no-data):hover {
      border-left: 4px solid $blue;
      background-color: $gray-800;
    }

    &.disabled {
      color: $gray-600;
    }

    .toolTip {
      font-weight: bold;
    }

    .btn-controls {
      display: flex;
      align-items: center;
      gap: 5px;

      .color {
        color: $blue;
        font-weight: bold;
      }

      .icon {
        background-color: transparent;
        border: none;
        display: grid;
        place-items: center;
        svg {
          color: #fff;
        }
        &:hover {
          svg {
            color: $blue;
          }
        }
      }
    }
  }
}
