.device {
  flex-grow: 1;
  min-height: calc(100vh - 155px);
}

.device__sidebar {
  width: 250px;
}

.device__drugs {
  width: 320px;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #3a4250;
}

.device__content {
  position: relative;
  flex-grow: 1;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #3a4250;
}

.device__facility--master-list {
  overflow-y: auto !important;
  max-height: calc(50vh - 130px) !important;
}

.device__care-area--master-list {
  overflow-y: auto !important;
  max-height: calc(50vh - 130px) !important;

  .master-list__item-delete {
    position: relative;
    width: 30px;
    height: 20px;
    right: -8px;
    top: -5px;
  }

  .device-list-drug--concentration-warning {
    position: relative;
    right: -9px;
  }
}

.device__care-area--title {
  position: relative;
  & > button {
    position: absolute;
    right: 0;
    top: 2px;
  }
}

.device__drugs--title {
  position: relative;
  & > button {
    position: absolute;
    right: 0;
    top: 2px;
  }
}

.device__care-area--full-list {
  max-height: calc(100vh - 300px) !important;
}
.device__drugs--full-list {
  max-height: calc(100vh - 180px) !important;
}

.device-list-drug--concentration {
  display: none !important;
  padding-left: 2.2rem !important;

  &.active-concentration {
    display: block !important;
  }
}

.device-list-drug--concentration-alert {
  display: none !important;
  padding-left: 2.2rem !important;

  &.active-concentration {
    display: block !important;
  }
}

#add-therapy-btn {
  padding: 0;
}

.device-list-drug--concentration-item {
  position: relative;
  background-color: #292e38;
  padding: 0.5rem 0 0.5rem 1rem;
  cursor: pointer;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #3e434c;
  border-left: 4px solid transparent;
  display: block;
  color: #f7f7f7;
  text-decoration: none;
  transition: all 0.1s ease-in;

  &:first-of-type {
    border-top: 1px solid #3e434c;
  }

  &:last-of-type {
    border-bottom: 1px solid #3e434c;
  }

  & > div {
    padding: 0.3rem 0.1rem 0.3rem 0.3rem;
  }

  &.selected {
    & > a {
      background-color: #3c485c;
    }

    .device-list-drug--concentration-delete {
      display: inline-block;
    }
  }

  &:hover,
  &:active,
  &.selected {
    color: #f7f7f7;
    background-color: #3c485c;
    border-left: 4px solid #0895df;
  }
}

.device-list-drug--concentration-level1 {
  padding-left: 2rem;

  .device-list-drug--concentration-arrow {
    left: 0.3rem;
  }
}

.device-list-drug--concentration-level2 {
  padding-left: 3rem;
}

.device-list-drug--concentration-arrow {
  position: absolute;
  top: 16px;
  left: 0;
}

.device__advisories--full-list {
  max-height: calc(100vh - 420px) !important;
}

.split-text-and-icons {
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 5px;
  gap: 2px;
}

.device-list-drug--icons {
  display: flex;
  align-items: center;
}

.device-list-drug--concentration-delete {
  display: none;
  background-color: transparent;
  border: none;
  color: #f7f7f7;
  padding-left: 2px;
  padding-right: 2px;

  &:hover {
    svg {
      color: #0895df;
    }
  }
}

.device-list-drug--concentration-warning {
  color: #fde500;
  margin-left: 2px;
  margin-right: 2px;
}

.device-list_rotate-icon-90 {
  transform: rotate(90deg);
}
