.drug-report-table {
  & > table {
    & > thead {
      display: table-header-group;
      display: table-row-group;
      opacity: 0.97;
      position: -webkit-sticky;
      position: sticky;
      z-index: 2;
      top: 80px;

      tr th {
        vertical-align: middle;
        border-bottom: 1px;
        & > div:first-of-type {
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }

  & .table > :not(caption) > * > * {
    border-right: 1px solid #3e434c;
    white-space: nowrap;
  }

  .react-select-container-sm {
    min-width: 200px;
  }
  .react-select-container-sm .react-select__control {
    height: auto !important;
  }
}

.drug-report__filters {
  background-color: #353d4a;
}
