%react-select__control {
  background-color: #363e4b !important;
  border-color: #424c5c !important;
}
%react-select__control-error {
  background-color: #ff5b5b !important;
  border-color: #d65108 !important;
}
.react-select-container {
  .react-select__control {
    @extend %react-select__control;
    height: 48px !important;
  }

  &.has-error {
    .react-select__control {
      @extend %react-select__control-error;
    }
  }
}
.react-select-container-multiselector {
  .react-select__control {
    @extend %react-select__control;
    max-height: 65px !important;
    overflow: auto;
  }
}
.react-select-container-sm {
  .react-select__control {
    @extend %react-select__control;
    min-height: 31px !important;
    height: 31px !important;
  }
  .react-select__dropdown-indicator {
    padding: 5px !important;
  }
  .react-select__value-container {
    padding: 0 8px;
  }
  .react-select__placeholder {
    font-weight: 400;
    font-size: 0.9rem;
  }
}
.react-select__single-value {
  color: #f7f7f7 !important;
}
.react-select__menu {
  background-color: #353d4a !important;
}
.react-select__input-container {
  color: #f7f7f7 !important;
}
.react-select__option {
  &:hover {
    cursor: pointer;
  }

  background-color: #353d4a !important;
  color: #f7f7f7 !important;

  &.react-select__option--is-focused {
    background-color: lighten(#353d4a, 10%) !important;
  }

  &.react-select__option--is-selected {
    background-color: #657794 !important;
  }
}
