.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #353D4A;
  color: #D1D5D6;
  z-index: 10;
}


.top-navbar__logo-wrapper {
  width: 200px;
  height: 80px;

  &.no-sidebar {
    width: auto;
    padding-left: 20px;
  }
}

.top-navbar__logo-link {

  img {
    width: 40px;
  }
}
