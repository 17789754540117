
label.radio-button {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;

	input {
		position: absolute;
		left: -9999px;
		&:checked + span {
			// background-color: mix(#fff, $blue, 84%);
			&:before {
				box-shadow: inset 0 0 0 10px $blue;
        // background-color: #fff;
			}
		}
	}

	span {
		display: flex;
		align-items: center;
		padding: 10px;
		transition: 0.25s ease;

		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			// background-color: #eee;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			margin-right: 6px;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 3px $blue;
		}
	}
}
