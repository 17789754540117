.user-profile {
  max-width: 800px;
  margin: 0 auto;
}

.user-profile__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3b4250;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.user-profile__header-subtitle {
  color: #aaa;
}
