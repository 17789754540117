.col-concentration-label {
  flex-basis: 180px;
  display: flex;
  align-items: center;
}

.col-concentration-input {
  flex: 1;
  display: flex;
  align-items: center;
  width: calc(100% - 180px);
}

.concentration-unit {
  padding-left: 1.7rem;
}

.concentration-input {
  width: 16em !important;
}

.concentration-select-input {
  width: 20em;
}

.concentration__input--drug-unit-full {
  min-width: 160px;
  padding-left: 1rem;
}

.concentration__input--amount,
.concentration__input--volume {
  &.has-error {
    background-color: #ff5b5b !important;
  }
}
