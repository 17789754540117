.users-table {
  & > table {
    & > thead tr th {
      & > div:first-of-type {
        padding: 0.5rem 0.5rem;
      }
    }
  }
}



.reports__content {
  position: relative;
  flex-grow: 1;
  min-height: calc(100vh - 120px);
}

.reports--master-list {
  max-height: auto;
}

