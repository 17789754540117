.phone-input {
  &.phone-input-lg {
    height: 48px;

    .PhoneInputInput {
      height: 48px;
    }
  }

  .PhoneInputInput {
    background-color: #363E4B;
    border: 1px solid #424C5C;
    color: #f7f7f7;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 0.15rem;
    padding-left: 5px;
  }
}
