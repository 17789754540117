$fullScreenWithoutNav: calc(100vh - 220px);
.master-list {
  max-height: $fullScreenWithoutNav;
  overflow-y: auto;
}
.master-list__no-scroll {
  max-height: none;
}

.master-list__full-screen {
  height: $fullScreenWithoutNav;
}

.master-list__item {
  position: relative;
  background-color: $dark;
  padding: 1rem;
  cursor: pointer;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #3e434c;
  border-left: 4px solid transparent;
  display: block;
  color: $body-color;
  text-decoration: none;
  transition: all 0.1s ease-in;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &:active,
  &.active {
    color: $body-color;
    background-color: #3c485c;
    border-left: 4px solid $blue;
  }

  &.active {
    .master-list__item-delete {
      display: inline-block;
    }
    .master-list__item-edit {
      display: inline-block;
    }
  }

  &.device-item {
    &:first-of-type {
      border-top: 1px solid #3e434c;
    }

    &:last-of-type {
      border-bottom: 1px solid #3e434c;
    }
  }

  &.item-disabled {
    color: #363d4a;
    pointer-events: none;

    &:hover,
    &:active,
    &.active {
      color: #363d4a;
      background-color: $dark;
      border-left: 4px solid transparent;
    }
  }
}

.master-list__item-delete {
  display: none;
  background-color: transparent;
  border: none;
  color: #f7f7f7;
  position: absolute;
  right: 10px;

  &:hover {
    svg {
      color: $blue;
    }
  }
}

.master-list__item-edit {
  display: none;
  background-color: transparent;
  border: none;
  color: #f7f7f7;
  position: absolute;
  right: 40px;

  &:hover {
    svg {
      color: $blue;
    }
  }
}


.master-list__search-wrapper {
  position: relative;

  .master-list__button-add {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  input[type='text'] {
    /* WebKit, Edge */
    &::-webkit-input-placeholder {
      color: #ccc;
    }
    /* Firefox 4-18 */
    &:-moz-placeholder {
      color: #ccc;
      opacity: 1;
    }
    /* Firefox 19+ */
    &::-moz-placeholder {
      color: #ccc;
      opacity: 1;
    }
    /* IE 10-11 */
    &:-ms-input-placeholder {
      color: #ccc;
    }
    /* Edge */
    &::-ms-input-placeholder {
      color: #ccc;
    }
    /* MODERN BROWSER */
    &::placeholder {
      color: #ccc;
    }
  }
}
