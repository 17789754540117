.table {
  margin: 0;

  tbody {
    td {
      a {
        text-decoration: none;
      }
    }
  }
}

.table-wrapper {
  background: #353D4A;
  padding: 10px;
  border-radius: 3px;
}

.table > :not(caption) th {
  padding: 0;
}

.table-header {
  padding: 0.5rem 0.5rem;
  background-color: #292E38;
}


.rotate-infinute {
  -webkit-animation:rotateInfinite 3s linear infinite;
  -moz-animation:rotateInfinite 3s linear infinite;
  animation:rotateInfinite 3s linear infinite;
}
@-moz-keyframes rotateInfinite {
  100% { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes rotateInfinite {
  100% { -webkit-transform: rotate(-360deg); }
}
@keyframes rotateInfinite {
  100% {
      -webkit-transform: rotate(-360deg);
      transform:rotate(-360deg);
  }
}
