.dl-list-pagination {
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  background-color: #363d4a;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: #d1d5d6;
    background: #4e586a;
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    cursor: pointer;
    outline: none;

    &:hover {
      background: darken(#4e586a, 5%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.dl-list-pagination__responsive {
      font-size: 0.8em;
    }
  }
}

.dl-list-pagination__previous {
  -ms-flex: 1;
  flex: 1 1;
  text-align: center;
}

.dl-list-pagination__center {
  -ms-flex: 2.5;
  flex: 2.5 1;
  text-align: center;
  margin-bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.dl-list-pagination__next {
  -ms-flex: 1;
  flex: 1 1;
  text-align: center;
}

.dl-list-pagination__page-info {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
  &.dl-list-pagination__responsive {
    font-size: 0.8rem;
    margin: 3px 0px;
  }
}

.dl-list-pagination__page-jump {
  display: inline-block;
  $input-default-width: 70px;
  input {
    width: $input-default-width;
    margin: 0 8px;
    &.dl-list-pagination__responsive {
      margin: 0 6px;
      width: $input-default-width - 10px;
    }

    text-align: center;

    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline: none;
  }
}

.dl-list-pagination__select-wrap {
  margin: 3px 10px;
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline: none;
  }
}

.dl-list-pagination__header {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  &.dl-list-pagination__asc {
    border-top: 2px solid #0895df;
  }
  &.dl-list-pagination__desc {
    border-bottom: 2px solid #0895df;
  }
}
