.device-concentration-form {
  .invalid-feedback {
    background: #ff5b5b;
    color: #fff;
    position: relative;
    margin-top: 1rem;
    padding: 5px 10px;
    border-radius: 3px;
  }

  .invalid-feedback:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ff5b5b;
    content: '';
    position: absolute;
    left: 13px;
    top: -7px;
  }
}

.device-concentration-form__drug {
  &--name {
    font-size: 1.5rem;
  }
  &--last-modified {
    font-size: 1rem;
    color: #6c757d;
    align-self: flex-start;
  }
}

.device-concentration-form__concentration--name,
.device-concentration-form__concentration--infusion-type,
.device-concentration-form__concentration--dose-mode,
.device-concentration-form__concentration--therapy {
  font-size: 1.2rem;
  min-height: 50px;
}


.device-concentration-form__select {
  display: flex;
  align-items: center;
  width: 220px !important;

  & > .react-select-container {
    width: 100%;
  }
}

.device-concentration-form__input {
  display: flex;
  align-items: center;
  width: 220px !important;

  &.intermittent {
    width: 100px !important;
  }

  & > .input {
    width: 100%;
  }
}

.customRow {
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  .label-cbx > span {
    position: relative;
    top: -2px;
  }

  label.radio-button span {
    padding: 0 10px 0 0;
  }

  &.align-basic-radios > :first-child {
    min-width: 165px;
  }

  &.allow-rapid-radios > :first-child {
    min-width: 5em;
  }
}

.device-concentration {
  &-form__label {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 180px !important;
    min-height: 50px;
  }

  &-disabled {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 180px !important;
    min-height: 50px;
    color: rgba(140, 142, 148, 255);
    cursor: not-allowed;
  }

  &-form__column {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: auto !important;
  }
}

.device-concentration-primary-secondary--width {
  width: 175px;
}

.device-concentration-primary-secondary2--width {
  width: 230px;
  margin-left: 30px;
}

.device-concentration__box-input {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 130px !important;

  input.has-error {
    background-color: #ff5b5b;
  }
}

.device-concentration__input--hard-limit {
  outline: 2px solid #d65108;

  &.has-error {
    background-color: #ff5b5b;
  }
}

.device-concentration__input--soft-limit {
  outline: 2px solid #efa00b;

  &.has-error {
    background-color: #ff5b5b;
  }
}

.device-concentration-units {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 220px !important;
}

.device-concentration-form__allow-load--wrapper {
  .form-control:disabled {
    // border-color: transparent;
    // outline: none;
  }
}

.device-concentration--dose-highlight {
  color: #fde500;
}

$overlay-z-index: 10;

.device-concentration--disabled-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  z-index: $overlay-z-index;
  cursor: not-allowed;
  background-color: rgba(41, 46, 56, 0.5);
}

.tooltip_over_disabled_overlay {
  z-index: $overlay-z-index + 1;
  position: relative;
}
