.page-container {
  display: block;
  padding-right: 40px;
  padding-left: 210px;
  width: 100%;
  padding-top: 110px;

  &.no-sidebar {
    padding-left: 40px;
  }
}
