/* User dropdown menu */
.top-navbar__user-dropdown {
  display: table-cell;
  &.open {
    .top-navbar__user-dropdown-menu {
      display: block;
    }
  }
}

.top-navbar__user-dropdown-menu {
  display: none;
  position: absolute;
  background: #313844;
  width: 300px;
  top: 92px;
  right: -10px;
  line-height: 1;
  border-radius: 5px;
  z-index: 1000;
  border: 1px solid #3a4250;
  &:before {
    position: absolute;
    top: -10px;
    right: 18px;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #3a4250;
  }
}

.top-navbar__user-dropdown-link {
  text-decoration: none;
  cursor: pointer;
  height: 80px;
  display: table;
  table-layout: fixed;
  vertical-align: middle;
  color: #d1d5d6;
  &:hover {
    color: #d1d5d6;
  }
  & > span {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.2rem;
  }
}

.top-navbar__user-dropdown-icon {
  border: 1px solid #d1d5d6;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}

.top-navbar__user-dropdown-menu__header {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a4250;
}

.top-navbar__user-dropdown-menu__header-title {
  font-size: 1.3rem;
  margin-bottom: 5px;
}

.top-navbar__user-dropdown-menu__header-subtitle {
  font-size: 0.9rem;
}

.top-navbar__user-dropdown__list {
  background: #313844;
  padding: 0;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  > li {
    &.divider {
      border-bottom: 1px solid #3a4250;
      margin: 10px 0;
    }
  }
}

.top-navbar__user-dropdown__link {
  display: flex;
  padding: 10px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  color: #d1d5d6;
  outline: none !important;
  align-items: center;
  &:hover {
    background: #3a4250;
    .top-navbar__user-dropdown__link-icon,
    .top-navbar__user-dropdown__link-text {
      color: #d1d5d6;
    }
  }
}

.top-navbar__user-dropdown__link-icon {
  color: #c1bfd1;
  display: table-cell;
  height: 100%;
  text-align: left;
  width: 30px;
  line-height: 0;
  padding-right: 4px;
}

.top-navbar__user-dropdown__link-text {
  display: table-cell;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  font-size: 1rem;
}
