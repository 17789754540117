.tabs__buttons {
  > span {
    cursor: pointer;
    display: inline-block;
    margin-left: 2px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.tabs__link {
  padding: 14px 18px;
  color: #aaa;
  text-decoration: none;
  display: inline-block;
  background: #313844;
  box-shadow: 0 -2px 6px #281C27 inset;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  font-size: 16px;
  &:hover {
    color: #f7f7f7;
  }

  &.active {
      box-shadow: none;
      color: #f7f7f7;
  }
}