.json-comparison-modal {
  .modal-sidebar-body {
    background: #292e38;
    padding: 0;
    padding: 0 20px;
  }

  .dl-changes-table {
    & > table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      & > thead {
        display: table-header-group;
        display: table-row-group;
        opacity: 0.97;
        position: -webkit-sticky;
        position: sticky;
        z-index: 2;
        top: 0px;

        tr th {
          vertical-align: middle;
          border-bottom: 1px;
          & > div:first-of-type {
            padding: 0.5rem 0.5rem;
          }
        }
      }
      
      & > tbody > tr > td {
        padding: 0;
        & > div {
          &:empty {
            &:before {
              content: ' ';
              display: inline-block;
            }
          }
          &.added {
            background-color: #758E4F;
          }
          &.deleted {
            background-color: #AD5D4E;
          }
          &.updated {
            background-color: #FBB13C;
          }
          padding: 0.5rem 0.5rem;
        }
      } 
    }

    & .table > :not(caption) > * > * {
      border-right: 1px solid #3e434c;
      white-space: nowrap;
    }

    .react-select-container-sm {
      min-width: 200px;
    }
    .react-select-container-sm .react-select__control {
      height: auto !important;
    }
  }

  .dl-changes-overflow {
    overflow-x: auto;
  }
}

.jsom-comparison-modal__tab-wrapper {
  background-color: #363D4A;
}