.advisories-form__message-tooltip-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -54px;
  top: -5px;

  & #manage-reasons-btn,
  #reset-basicadvisory-btn {
    width: 29.25px;
    height: 29.25px;
    margin-top: 17px;
  }

  &--edit {
    position: relative;
    right: -1px;
  }
}

.advisories-form__message-reset {
  display: inline-block;
  position: absolute;
  right: -62px;
  top: 50px;
}

.advisory-form__message-body {
  height: 420px;
  font-family: 'Cabin';
}

.advisories-form__message {
  width: 100%;
  background: transparent;
  color: #fff;
  border: none;
  font-size: 25pt;
  font-weight: bold;
  line-height: 30pt;
  font-family: 'Cabin';
  padding: 0 10px;
  text-align: center;
  outline: none;
  max-height: 420px;
  resize: none;
  overflow-y: hidden;

  &:focus::placeholder {
    color: transparent;
  }

  &:focus,
  &:active {
    border: none;
  }
}

.advisory-form__message-wrapper {
  position: relative;
  width: 450px;
  height: 600px;
  box-sizing: border-box;
  border: 4px solid #0895df;
  border-radius: 10px;
  &.basic-mode-advisory {
    border: 4px solid #fde500;
  }
}

.advisory-form__message-title {
  background: #0895df;
  color: #fff;
  font-size: 2rem;
  text-align: center;
  position: relative;

  &.basic-mode-advisory {
    background: #fde500;
    color: #292e38;
  }
}

.advisory-form__message-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  user-select: none;
  > span {
    display: inline-block;
    text-align: center;
    padding-top: 18px;
    width: 170px;
    height: 86px;
    color: #fff;
    font-size: 2rem;
    border: none;
    border-radius: 10px;
  }
  &--back {
    background-color: #d50404;
    margin-right: 40px;

    &.message-buttons--back-center {
      margin-right: 0;
    }
  }

  &--ok {
    background-color: #00950f;
  }
}

.advisory-form__edit-title {
  position: relative;
  top: -5px;
  left: 10px;
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: #0895df;
  }
}

.advisory-reasons-master-list {
  max-height: calc(100vh - 175px) !important;
}
