.login-page {
  min-height: 100vh;
}

.login-page__logo-wrapper {
  background-color: #fff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  img {
    width: 100px;
  }
}
