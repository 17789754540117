.content-header {
  padding: 0 0 50px 0;
  display: block;
  position: relative;
}

.content-header__title {
  padding: 0;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-shadow: 0 0 1px transparent;
  display: block;
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: 1px;
  padding-bottom: 1rem;
}

.content-header__links {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  > li {
      float: left;
      margin-left: 2px;
      &:first-child {
          margin-left: 0;
      }
  }
}

.content-header__link {
  padding: 14px 18px;
  color: #aaa;
  text-decoration: none;
  display: inline-block;
  background: #313844;
  box-shadow: 0 -2px 6px #281C27 inset;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  font-size: 16px;
  &:hover {
    color: #f7f7f7;
  }

  &.active {
      box-shadow: none;
      color: #f7f7f7;
  }
}

.content-header__actions {
  float: right;
  position: relative;
  top: -11px;
}
