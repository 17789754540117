@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-picky/dist/picky.css';
@import '~react-phone-number-input/style.css';
@import './container';
@import './helpers';
@import './buttons';
@import './forms';
@import './master-list';
@import './fonts';

//Components
@import './components/deployment/deployment-form';
@import './components/deployment/deployment-history';
@import './components/help/help';
@import './components/tooltip';
@import './components/table';
@import './components/select';
@import './components/radio';
@import './components/picky';
@import './components/phone-input';
@import './components/editor';
@import './components/modal';

// Amplify
:root {
  --amplify-components-button-primary-background-color: #0895df;
  --amplify-components-button-primary-focus-background-color: #035e8f;
  --amplify-components-button-primary-hover-background-color: #035e8f;
  --amplify-components-button-primary-active-background-color: #035e8f;
  --amplify-components-button-link-color: #035e8f;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}
