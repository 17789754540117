@font-face {
  font-family: 'Cabin';
  src: url('../assets/fonts/Cabin-Regular.eot');
  src: url('../assets/fonts/Cabin-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Cabin-Regular.woff2') format('woff2'),
    url('../assets/fonts/Cabin-Regular.woff') format('woff'),
    url('../assets/fonts/Cabin-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
