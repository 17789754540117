.form-control {
  background-color: #363E4B;
  border-color: #424C5C;

  &:focus {
    border-color: #424C5C;
  }

  &:disabled {
    background-color: #292e38;
    border-color: #292E38;
  }
}

.invalid-feedback {
  display: block;
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border-radius: 2px;
  margin-right: 3rem;
  background-color: #363E4B;
  border-color: #424C5C;

  &:focus {
    border-color: #424C5C;
  }

  &.form-select-lg {
    background-position-x: 100%;
    background-position-y: 11px;
  }
}

a.disabled {
  background: #575F69;
  pointer-events: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
