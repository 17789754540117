.modal-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 600px;
  height: 100vh;
  // background: $modal-sidebar-bg;
  z-index: 1050;
  // @include transition(0.15s all ease-in);

  // &.modal-sidebar-open {
  //   animation: 0.2s ease-in slideInFromRight;
  // }

  // &.modal-sidebar-close {
  //   animation: 0.2s ease-in slideInToRight;
  // }
  &.full {
    width: 100vw;
  }
}

.modal-sidebar-header {
  padding: 20px 30px;
  font-size: 1.4rem;
  background: #292e38;
  .modal-sidebar-header__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: #6c757d;
    transition: all 0.1s ease-in;
    &:hover {
      background: #5c636a;
    }
  }
}

.modal-sidebar-body {
  height: calc(100vh - 143px);
  overflow-y: scroll;
  font-size: 1rem;
  padding: 20px 30px;
  position: relative;
  background: #313844;
}

.modal-sidebar-body--full {
  height: calc(100vh - 63px);
}

.modal-sidebar-footer {
  position: absolute;
  background: #353d4a;
  border-top: 1px solid #3a4250;
  padding: 15px 30px;
  height: 80px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-sidebar-footer--empty {
  position: absolute;
  background: #313844;
  padding: 15px 30px;
  height: 80px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
