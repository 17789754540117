.label-cbx-invisible {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;
}

.label-cbx {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    input:checked {
        &+.checkbox {
            border-color: #2777AC;
            svg {
                path {
                    fill: #2777AC;
                }
                polyline {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    input:disabled {
        &+.checkbox {
            border-color: #ccc;
            svg {
                path {
                    fill: #ccc;
                }
            }
        }
        &:hover {
            .checkbox {
                svg {
                    path {
                        stroke: none;
                    }
                }
            }
        }
    }

    &:hover {
        .checkbox {
            svg {
                path {
                    stroke-dashoffset: 0;
                }
            }
        }
    }
    .checkbox {
        position: relative;
        top: 2px;
        float: left;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        border: 2px solid #C8CCD4;
        border-radius: 3px;
        svg {
            position: absolute;
            top: -2px;
            left: -2px;
            path {
                fill: none;
                stroke: #2777AC;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 71px;
                stroke-dashoffset: 71px;
                transition: all .6s ease;
            }
            polyline {
                fill: none;
                stroke: #FFF;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 18px;
                stroke-dashoffset: 18px;
                transition: all .3s ease;
            }
        }
    }
    >span {
        pointer-events: none;
        vertical-align: middle;
    }
}

.checkbox-margin-none {
    .label-cbx {
        .checkbox {
            margin: 0;
        }
    }
}