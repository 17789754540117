.__react_component_tooltip {
  font-size: 16px;
}

.label--tooltip {
  position: relative;
  display: inline-block;
  top: -1px;
  left: 10px;
}
