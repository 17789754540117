.picky__dropdown {
  background: #363E4B;
  border-bottom: 1px solid #424C5C;;
  border-left: 1px solid #424C5C;;
  border-right: 1px solid #424C5C;;
}

.picky__dropdown .option, .picky__dropdown li {
  background-color: #363E4B;
  border-bottom: 1px solid #424C5C;
}

.picky__input {
  height: 48px;
  background-color: #363E4B;
  border-color: #424C5C;
  color: #f7f7f7;
}

.picky__placeholder {
  color: #6B747C;
}

.picky__dropdown .option:hover, .picky__dropdown li:focus,
.picky__dropdown .option.selected, .picky__dropdown li.selected,
.picky__dropdown .option:hover, .picky__dropdown li:hover {
  background-color: #424C5C;
  outline: none;
}
