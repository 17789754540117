.care-area__name {
  max-width: 929px;
}

.col-care-area-label {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 230px !important;
  min-height: 50px;
}

.col-care-area-input {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 180px !important;
}

.col-care-area-radio {
  display: flex;
  align-items: center;
  width: auto !important;
}

.align_value_reentry {
  &:nth-child(1) {
    position: relative;
    left: -12px;
  }
  &:nth-child(2) {
    padding-left: 6px;
  }
}

.align_audio_level_alarm {
  &:nth-child(1) {
    position: relative;
    left: -13px;
  }
  &:nth-child(2) {
    padding-left:20px;
  }
  &:nth-child(3) {
    padding-left: 33px;
  }
}

.col-care-area-units {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 80px !important;
}

.care-area__input--hard-limit {
  outline: 2px solid #d65108;

  &.has-error {
    background-color: #ff5b5b;
  }
}

.care-area__input--soft-limit {
  outline: 2px solid #efa00b;

  &.has-error {
    background-color: #ff5b5b;
  }
}
.care-area__label--tooltip {
  position: relative;
  display: inline-block;
  top: -1px;
  left: 10px;
}

.care-area__kvo-rate {
  &.has-error {
    background-color: #ff5b5b;
  }
}

.col-care-area {
  display: flex;
  flex: 0 0 auto;
  width: auto !important;
}

.care-area__edit-title {
  position: relative;
  top: -5px;
  left: 10px;
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: #0895df;
  }
}
